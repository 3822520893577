import { memo, useEffect, useState } from 'react';

import { Modal, DatePicker, Table, Flex, Button, Divider } from 'antd';

import dayjs from 'dayjs';

import { REQUESTS } from '../../../api/requests';

import Text from '../../../components/Text';

import styles from "../index.module.scss";

function Statistics({ current, open, onCancel, }) {
    const [data, setData] = useState([]);
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [loading, setLoading] = useState(false);

    const platforms = ["tizen", "webos", "android", "vidaa", "foxxum", "zeasn", "vizio", "tvos", "ios", "roku"];

    const getStatistics = () => {
        if (!current) return;

        setLoading(true);

        const query = {
            id: current.id,
        }

        if (year && month) {
            query.year = year;
            query.month = month;
        }

        REQUESTS.FREE_DNS_STATISTICS(query, (res) => {
            setLoading(false);

            let data = res.apps;

            let dataModel = data.map(item => {
                let obj = {
                    api_url: item.api_url,
                    id: item.id,
                    name: item.name,
                };

                if (item.statistics.error) {
                    return obj
                } else {
                    return {
                        ...obj,
                        ...item.statistics.message
                    }
                }
            });

            setData(dataModel);

        }, (error) => { setLoading(false) })
    }

    const availablePlatforms = platforms.filter(platform => {
        return data?.some(item => item?.hasOwnProperty(platform))
    });

    const columns = [
        {
            title: 'App',
            dataIndex: 'name',
            key: 'name',
        },
        ...availablePlatforms.map(platform => ({
            title: platform ? platform == "tizen" ? "Samsung" : platform == "webos" ? "LG" : platform[0].toUpperCase() + platform.substring(1) : '',
            dataIndex: platform,
            key: platform,
            render: (text, record) => {
                return <strong>{record[platform]}</strong>
            }
        })),
    ]

    const onChange = (date, dateString) => {
        setYear(dateString.split('-')[0])
        setMonth(dateString.split('-')[1])
    };

    useEffect(() => {
        if (!open) return;

        let timeout = setTimeout(getStatistics, 500)

        return () => {
            clearTimeout(timeout)
        }
    }, [current, open, year, month]);

    useEffect(() => {
        if (open) {
            let month = new Date().getMonth() + 1;
            let year = new Date().getFullYear();
            setMonth(month < 10 ? `0${month}` : month);
            setYear(year);
        } else {
            setData([]);
            setYear(null);
            setMonth(null);
        }
    }, [open]);

    return (
        <Modal
            title={<Text>Free DNS Statistic</Text>}
            open={open}
            destroyOnClose={true}
            onCancel={onCancel}
            footer={null}
        >
            <DatePicker onChange={onChange} picker="month" defaultValue={dayjs(`${year}-${month}`, 'YYYY-MM')} allowClear={false} />

            <Divider />

            <div className={styles['dns']}>{current?.dns}</div>

            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                scroll={{ x: 'max-content' }}
                summary={pageData => {
                    const totals = availablePlatforms.reduce((acc, platform) => {
                        acc[platform] = 0;
                        return acc;
                    }, {});

                    pageData.forEach(dataItem => {
                        availablePlatforms.forEach(platform => {
                            totals[platform] += dataItem[platform] || 0;
                        });
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell><Text>Total</Text></Table.Summary.Cell>
                                {availablePlatforms.map(platform => (
                                    <Table.Summary.Cell key={platform}>
                                        <strong>{totals[platform]}</strong>
                                    </Table.Summary.Cell>
                                ))}
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />

            <div className={styles['total']}><Text>Total</Text>: {
                data.reduce((acc, item) => {
                    return acc + availablePlatforms.reduce((acc, platform) => {
                        return acc + (item[platform] || 0);
                    }, 0);
                }, 0)
            }</div>

            <Flex justify="flex-end" style={{ marginTop: 20 }}>
                <Button type='primary' onClick={onCancel}><Text>Close</Text></Button>
            </Flex>
        </Modal >
    )
}

export default memo(Statistics);