import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { List, Empty, Alert, Flex } from 'antd';
import { BellOutlined } from '@ant-design/icons';

import PageTemplate from '../../components/PageTemplate';

import Text from '../../components/Text';

import dateFormat from '../../utils/dateFormat';

import { REQUESTS } from '../../api/requests';

import NotificationModal from '../../components/NotificationModal';

import ICONS from '../../config/icons';

import styles from "./index.module.scss";

export default function Notifications() {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(null);

    const profile = useSelector(state => state.profile.value);

    const getNotification = () => {
        setLoading(true);

        if (!profile.email) return;

        const query = {
            email: profile.email,
        }

        if (profile.is_partner) {
            query.status = "partner"
        } else if (profile.parent_id) {
            query.status = "subreseller"
        } else if (!profile.is_reseller) {
            query.status = "reseller"
        }

        const callback = (data) => {
            setLoading(false);
            data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setData(data);
        }

        const errorCallback = (error) => {
            setLoading(false);
        }

        REQUESTS.NOTIFICATIONS.GET(query, callback, errorCallback)
    }

    useEffect(() => {
        if (profile) getNotification()
    }, [profile]);

    return (<>
        <div className={styles['warning-container']}>
            {profile?.blocked && <Alert
                description={<Flex align='center' gap={10} >{ICONS.BLOCKED_PROFILE} <span className={styles['warning-text']}>Your Account Is Blocked</span> </Flex>}
                type="error"
            />}
        </div>
        <PageTemplate title={<Text>Notifications</Text>}>
            <List
                loading={loading}
                locale={{ emplyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            className={styles['notification-item']}
                            onClick={() => {
                                setCurrent(item);
                                setOpen(true);
                            }}
                            avatar={item.image ? <img src={item.image} className={styles['img']} /> : <BellOutlined style={{ fontSize: '24px', color: '#429229' }} />}
                            description={<Flex gap={15}>

                                <div>
                                    <h3>{item.title}</h3>
                                    <strong>{item.createdAt && dateFormat(item.createdAt)}</strong>
                                </div>
                            </Flex>}
                        />
                    </List.Item>
                )}
            />
            <NotificationModal
                open={open}
                setOpen={setOpen}
                notification={current}
            />
        </PageTemplate>
    </>
    )
}
