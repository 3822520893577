import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

import axios from "axios";

const CryptoJS = require("crypto-js");

export const REQUESTS = {
  RECAPCHA_SITE_KEY: (callback, errorCallback,) => {
    request(
      "get",
      URLS.RECAPCHA_SITE_KEY,
      {},
      callback,
      errorCallback,
    );
  },

  LOGIN: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.LOGIN,
      body,
      callback,
      errorCallback,
    );
  },

  APPS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.APPS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  CREDITS: {
    ADD: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.ADD_CREDIT,
        body,
        callback,
        errorCallback,
      );
    },
    TAKE: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.TAKE_CREDIT,
        body,
        callback,
        errorCallback,
      );
    },
  },
  CREDIT_HISTORY: (params, callback, errorCallback) => {
    request(
      "get",
      URLS.CREDIT_HISTORY + requestQuery(params),
      {},
      callback,
      errorCallback,
    );
  },
  CHECK_MAC: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.CHECK_MAC + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  DEVICES: (params, callback, errorCallback) => {
    request(
      "get",
      URLS.DEVICES + requestQuery(params),
      {},
      callback,
      errorCallback,
    )
  },
  EDIT_DEVICE_REMARK: (body, callback, errorCallback) => {
    request(
      "put",
      URLS.DEVICES,
      body,
      callback,
      errorCallback,
    )
  },
  DEVICES_ACTIVATION: (body, callback, errorCallback) => {
    request(
      "post",
      URLS.DEVICES_ACTIVATION,
      body,
      callback,
      errorCallback,
    );
  },
  PROFILE: {
    GET: (callback, errorCallback,) => {
      request(
        "get",
        URLS.PROFILE,
        {},
        callback,
        errorCallback,
      );
    },
    UPDATE: (body, callback, errorCallback,) => {
      request(
        "put",
        URLS.PROFILE,
        body,
        callback,
        errorCallback,
      );
    },
  },
  PACKAGES: (query, callback, errorCallback,) => {
    request(
      "get",
      URLS.PACKAGES + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  SUB_RESELLERS: {
    GET: (params, callback, errorCallback,) => {
      request(
        "get",
        URLS.SUB_RESELLERS + requestQuery(params),
        {},
        callback,
        errorCallback,
      );
    },
    CREATE: (body, callback, errorCallback,) => {
      request(
        "post",
        URLS.SUB_RESELLERS,
        body,
        callback,
        errorCallback,
      );
    },
    UPDATE: (body, callback, errorCallback,) => {
      request(
        "put",
        URLS.SUB_RESELLERS,
        body,
        callback,
        errorCallback,
      );
    },
  },
  PLAYLIST: {
    GET: (query, callback, errorCallback,) => {
      request(
        "get",
        URLS.PLAYLIST + requestQuery(query),
        {},
        callback,
        errorCallback,
      )
    },
    CREATE: (body, callback, errorCallback,) => {
      request(
        "post",
        URLS.PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    },
    EDIT: (body, callback, errorCallback,) => {
      request(
        "put",
        URLS.PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    },
    DELETE: (body, callback, errorCallback,) => {
      request(
        "delete",
        URLS.PLAYLIST,
        body,
        callback,
        errorCallback,
      );
    }
  },
  //STATISTICS
  DEVICES_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.DEVICES_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  SUBRESELLERS_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.SUBRESELLERS_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  CREDITS_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.CREDITS_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  APP_TOTAL_DEVICES_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.APP_TOTAL_DEVICES_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  DEVICES_BY_MODEL_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.DEVICES_BY_MODEL_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },
  DEVICES_BY_COUNTRY_STATISTICS: (callback, errorCallback,) => {
    request(
      "get",
      URLS.DEVICES_BY_COUNTRY_STATISTICS,
      {},
      callback,
      errorCallback,
    );
  },

  GET_VERSION: (callback, errorCallback) => {
    request(
      "get",
      URLS.VERSION,
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS: (params, callback, errorCallback) => {
    request(
      "get",
      URLS.FREE_DNS + requestQuery(params),
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS_DEVICES: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.FREE_DNS_DEVICES + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS_DEVICES_BY_STATISTICS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.FREE_DNS_DEVICES_BY_STATISTICS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS_STATISTICS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.FREE_DNS_STATISTICS + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS_STATISTICS_ALL: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.FREE_DNS_STATISTICS_ALL + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },
  FREE_DNS_STATISTICS_APPS_ALL: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.FREE_DNS_STATISTICS_APPS_ALL + requestQuery(query),
      {},
      callback,
      errorCallback,
    );
  },

  NOTIFICATIONS: {
    request: (method, url, body, callback = () => { }, errorCallback = () => { }) => {
      let myAxios = axios.create();

      let TOKEN = localStorage.getItem("RES_TOKEN");

      myAxios.defaults.headers = {
        "Authorization": `Bearer ${TOKEN}`,
        "x-access-token": REQUESTS.NOTIFICATIONS.CRYPTO(new Date().getTime().toString())
      }

      myAxios[method](url, method === "delete" ? { data: body } : body)
        .then((response) => {
          if (response?.data) {
            callback(response.data);
          }
        })
        .catch((error) => {
          let message = error?.response?.data
          if (message == "Forbidden") {
            localStorage.clear();
            window.location.href = "/";
            return;
          }

          if (message == "Your account is blocked" && !url.includes("login")) {
            localStorage.clear();
            window.location.href = "/";
            return;
          }

          errorCallback(error?.response?.data);
        });
    },
    CRYPTO: (str) => {
      try {
        return CryptoJS.AES.encrypt(str, "noeyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9t").toString();
      } catch (error) {
        console.error(error);
      }
    },
    GET: (query, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "get",
        URLS.NOTIFICATIONS + requestQuery(query),
        {},
        callback,
        errorCallback,
      );
    },
    VIEW: (body, callback, errorCallback) => {
      REQUESTS.NOTIFICATIONS.request(
        "post",
        URLS.NOTIFICATIONS,
        body,
        callback,
        errorCallback,
      );
    },
  }
};
