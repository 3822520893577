import { useState, useEffect } from 'react';

import { Collapse, Table, Empty } from 'antd';

import { REQUESTS } from '../../../api/requests';

import Text from '../../../components/Text';

import styles from "../index.module.scss";

export default function StatisticsByDns({ date }) {
    const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);

    const getAllDNS = () => {
        const body = {};
        const { year, month } = date

        if (year && month) {
            body.year = year;
            body.month = month;
        }

        REQUESTS.FREE_DNS_STATISTICS_ALL(body, (res) => {

            let total = res.reduce((acc, item) => {
                item.apps.forEach(app => {
                    if (app.statistics.error) return;
                    Object.values(app.statistics.message).forEach((value) => {
                        acc += value;
                    });
                });
                return acc;
            }, 0);

            setTotal(total);

            let items = res.map((item, index) => {
                let columns = [
                    {
                        title: 'App',
                        dataIndex: 'name',
                        key: 'name',
                    },
                ];

                let dataSource = item.apps.map((app, idx) => {
                    let data = {
                        key: idx,
                        name: app.name,
                    };

                    if (app.statistics.error) return data;

                    Object.entries(app.statistics.message).forEach(([key, value]) => {
                        if (!columns.some(col => col.key === key)) {
                            columns.push({
                                title: key ? key == "tizen" ? "Samsung" : key == "webos" ? "LG" : key[0].toUpperCase() + key.substring(1) : '',
                                dataIndex: key,
                                key: key,
                                render: (text, record) => {
                                    return <strong>{record[key]}</strong>
                                }
                            });
                        }
                        data[key] = value;
                    });

                    return data;
                });

                return {
                    key: index,
                    label: item.dns,
                    children: <Table
                        scroll={{ x: 'max-content' }}
                        pagination={false}
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        summary={pageData => {
                            let columnTotals = {};
                            columns.forEach(col => {
                                if (col.dataIndex !== 'name') {
                                    columnTotals[col.dataIndex] = pageData.reduce((acc, item) => acc + (item[col.dataIndex] || 0), 0);
                                }
                            });
                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                                        {columns.slice(1).map(col => (
                                            <Table.Summary.Cell key={col.key}>
                                                <strong>{columnTotals[col.dataIndex]}</strong>
                                            </Table.Summary.Cell>
                                        ))}
                                    </Table.Summary.Row>
                                </>
                            );
                        }}
                    />,
                };
            });

            setItems(items);
        });
    };

    useEffect(() => {
        getAllDNS();
    }, [date]);

    return (
        <div>
            {
                items.length ? (
                    <div>
                        <Collapse
                            className={styles['collaps']}
                            items={items}
                        />
                        <div className={styles['total']}>
                            <Text>Total</Text>: {total}
                        </div>
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
            }
        </div>
    )
}
