import React, { useEffect, useState } from 'react'

import { Table, Button, Input, Tooltip, Flex, Tag } from 'antd';
import {
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import dateFormat from '../../utils/dateFormat';

import SubResellerDrawer from './components/SubResellerDrawer';

import CreditsDrawer from './components/CreditsDrawer';

import PageTemplate from "../../components/PageTemplate";

import PageDescription from './components/PageDescription';

import ExportCSVModal from '../../components/ExportCSVModal';

import filterDropdown from '../../utils/FilterDropdown';

import useResize from '../../hooks/use-resize';
import ActionDropdown from '../../utils/ActionDropdown';
import Text from '../../components/Text';

export default function Resellers() {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [creditUpdate, setCreditUpdate] = useState(false);
  const [current, setCurrent] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(["createdAt", "DESC"]);
  const [expiredDate, setExpiredDate] = useState("");
  const [filter, setFilter] = useState("");
  const [info, setInfo] = useState(false);
  const [date, setDate] = useState("");

  const resize = useResize();

  const [openExportModal, setOpenExportModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [exportDate, setExportDate] = useState("");

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: <Text>Name</Text>,
      dataIndex: 'name',
      sorter: true
    },
    {
      title: <Text>Email</Text>,
      dataIndex: 'email',
      sorter: true
    },
    {
      title: <Text>Credit Point</Text>,
      align: "center",
      dataIndex: 'credits',
      sorter: true
    },
    {
      title: <Text>Activated Devices</Text>,
      align: "center",
      dataIndex: 'device_count',
      sorter: true
    },
    {
      title: <Text>Remark</Text>,
      align: "center",
      dataIndex: 'remark',
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip placement="topLeft" title={record?.remark}>
          {record?.remark}
        </Tooltip>
      ),
    },
    {
      title: <Text>Created Date</Text>,
      dataIndex: 'createdAt',
      align: "center",
      sorter: true,
      ...filterDropdown(setDate),
      render: (text, record) => record.createdAt ? dateFormat(record.createdAt) : "-"
    },
    {
      title: <Text>Status</Text>,
      align: "center",
      dataIndex: 'blocked',
      sorter: true,
      filters: [
        {
          text: 'Active',
          value: false,
        },
        {
          text: 'Blocked',
          value: true,
        }
      ],
      filterMultiple: false,
      sorter: "true",
      render: (text, record) => {
        return record.blocked ?
          <Tag color={'red'}>
            <Text>Blocked</Text>
          </Tag> :
          <Tag color={"green"} >
            <Text>Active</Text>
          </Tag>
      }
    },
    {
      title: <Text>Action</Text>,
      key: 'action',
      align: "center",
      render: (text, record) => {
        return <ActionDropdown items={[
          {
            key: "1",
            label: <div >
              <EditOutlined style={{ cursor: "pointer" }} /> <Text>Edit</Text>
            </div>,
            onClick: () => {
              setCurrent(record);
              setOpen(true);
            }
          },
          {
            key: "2",
            label: <div>
              <PlusOutlined style={{ cursor: "pointer" }} /> <Text>Add Credit</Text>
            </div>,
            onClick: () => {
              setCurrent(record);
              setCreditUpdate("Add Credit");
            }
          },
          {
            key: "3",
            label: <div>
              <MinusOutlined style={{ cursor: "pointer" }} /> <Text>Take Credit</Text>
            </div>,
            onClick: () => {
              setCurrent(record);
              setCreditUpdate("Take Credit");
            }
          }
        ]} />
      },
    },
  ];

  const getSubResellers = () => {
    setLoading(true)

    const params = {
      limit: limit,
      page: currentPage,
      sort,
    }

    if (search.length) {
      params.search = {
        name: search,
        email: search
      }
    }

    if (filter["blocked"]) {
      params["filter"] = {
        blocked: filter["blocked"][0]
      }
    }

    const dateFilter = [
      { date: openExportModal, between: 'createdAt' },
      { date: date, between: "createdAt" },
    ]

    dateFilter.forEach((item) => {
      if (item.date.length && item.date[0] && item.date[1]) {
        params['between'] = {
          [item.between]: {
            from: item.date[0] + " 00:00",
            to: item.date[1] + " 23:59"
          }
        }
      }
    })

    REQUESTS.SUB_RESELLERS.GET({ query: JSON.stringify(params) }, (data) => {
      setLoading(false);
      setLimit(data.limit)
      setTotal(data.count)
      setDataSource(data.rows)
    }, (error) => {
      setLoading(false)
    })
  }

  const handleOnChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
    setFilter(filters);
    if (sorter.field && sorter.order) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    } else {
      setSort(["createdAt", "DESC"])
    }
  }

  const getExportData = () => {
    const query = {
      pagination: false,
    };

    if (exportDate && exportDate[0] && exportDate[1]) {
      query.between = {
        ["createdAt"]: {
          from: exportDate[0] + " 00:00",
          to: exportDate[1] + " 23:59"
        }
      }
    }

    REQUESTS.SUB_RESELLERS.GET({ query: JSON.stringify(query) }, (data) => {
      setLoading(false);
      setCsvData(data)
    }, (error) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (exportDate.length) getExportData()
  }, [exportDate])

  useEffect(() => {
    let interval = setTimeout(() => {
      getSubResellers()
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [currentPage, limit, search, sort, date, expiredDate, filter]);

  return (
    <PageTemplate title={<Text>Resellers</Text>} setInfo={setInfo}>
      <Flex wrap="wrap" gap="small" justify='space-between' style={{ marginBottom: 20 }}>
        <Input
          placeholder="Search"
          prefix={<SearchOutlined />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 300 }}
        />
        <Flex gap="small">
          {/* <Button onClick={() => setOpenExportModal(true)}>Export to CSV</Button> */}

          <Button onClick={() => {
            if (current) setCurrent(null);
            setOpen(true)
          }} type="primary"><PlusOutlined /><Text>Add Reseller</Text></Button>
        </Flex>
      </Flex>

      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={handleOnChange}
        pagination={{
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        bordered
        scroll={{ x: "max-content" }}
        size={resize <= 900 ? "small" : "large"}
        footer={() => <strong><Text>Total</Text>: {total}</strong>}
      />
      <SubResellerDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          if (current) setCurrent(null);
        }}
        getSubResellers={getSubResellers}
        current={current}
      />
      <CreditsDrawer
        creditUpdate={creditUpdate}
        onClose={() => {
          setCreditUpdate(false);
          if (current) setCurrent(null);
        }}
        getSubResellers={getSubResellers}
        current={current}
      />
      <PageDescription
        open={info}
        setOpen={setInfo}
      />
      {/* <ExportCSVModal
        title="Resellers"
        open={openExportModal}
        setDate={setExportDate}
        data={csvData}
        handleCancel={() => {
          setOpenExportModal(false);
        }}
      /> */}
    </PageTemplate>
  )
}
