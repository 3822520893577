import { useEffect, useState } from 'react';

import { Modal, Tabs, DatePicker, Flex, Button } from 'antd';

import dayjs from 'dayjs';

import StatisticsByApps from './StatisticsByApps';
import StatisticsByDns from './StatisticsByDns';
import Text from '../../../components/Text';

export default function TotalStatistics({ open, onCancel }) {
    const [activeTab, setactiveTab] = useState("1");

    const [year, setYear] = useState(dayjs().year());
    const [month, setMonth] = useState(dayjs().month() + 1);

    const onChange = (date, dateString) => {
        setYear(dateString.split('-')[0]);
        setMonth(dateString.split('-')[1]);
    };

    useEffect(() => {
        if (!open) {
            setYear(dayjs().year());
            setMonth(dayjs().month() + 1);
        }
    }, [open]);

    return (
        <Modal
            title={<Text>Free DNS Statistics</Text>}
            open={open}
            destroyOnClose={true}
            onCancel={onCancel}
            footer={null}
        >
            <DatePicker
                onChange={onChange}
                picker="month"
                defaultValue={year && month ? dayjs(`${year}-${month}`, 'YYYY-MM') : null}
            />
            <Tabs
                defaultValue={activeTab}
                destroyInactiveTabTabPane={true}
                items={[
                    {
                        key: '1',
                        label: <Text>Statistics By Apps</Text>,
                        children: <StatisticsByApps date={{ year, month }} />,
                    },
                    {
                        key: '2',
                        label: <Text>Statistics By DNS</Text>,
                        children: <StatisticsByDns date={{ year, month }} />,
                    },
                ]}
                onChange={(key) => setactiveTab(key)}
            />
            <Flex justify='flex-end'>
                <Button type="primary" onClick={onCancel}>Close</Button>
            </Flex>
        </Modal>
    );
}

