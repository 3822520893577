import { useEffect, useState } from "react";

import { Button, Drawer, Flex, Modal, Table, Tooltip, } from "antd";

import {
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleFilled
} from '@ant-design/icons';

import { REQUESTS } from "../../../api/requests";

import useResize from "../../../hooks/use-resize";

import dateFormat from "../../../utils/dateFormat";

import PlaylistActionsDrawer from "./PlaylistsActionsDrawer";

import ActionDropdown from "../../../utils/ActionDropdown";
import Text from "../../../components/Text";
import useStore from "../../../hooks/use-store";
import TEXTS from "../../../config/resellerText";

const { confirm } = Modal

export default function PlaylistDrawer({ current, open, onClose }) {
    const [playlists, setPlaylists] = useState([]);

    const [loading, setLoading] = useState([]);

    const [visible, setVisible] = useState(false);

    const [actionType, setActionType] = useState("add");

    const [playlist, setPlaylist] = useState({});
    const [language] = useStore("language")

    const resize = useResize();

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            render: (text, record, index) => {
                return index + 1
            }
        },
        {
            title: <Text>Name</Text>,
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            ellipsis: {
                showTitle: false,
            },
            render: (text, record) => {
                return <Tooltip placement="topLeft" title={record.url}>
                    {record.url}
                </Tooltip>
            }
        },
        {
            title: <Text>Hide URL</Text>,
            dataIndex: 'hide_url',
            key: 'hide_url',
            width: 100,
            align: "center",
            render: (text, record) => {
                return record.hide_url ? <CheckOutlined style={{ color: "green" }} /> :
                    <CloseOutlined style={{ color: "red" }} />
            }
        },
        {
            title: <Text>Created Date</Text>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: "center",
            render: (text, record) => {
                return record && record.createdAt ? dateFormat(record.createdAt) : "-"
            }
        },
        {
            title: <Text>Action</Text>,
            key: 'Action',
            align: "center",
            width: 90,
            render: (text, record) => {
                return <ActionDropdown items={[
                    {
                        key: 'edit',
                        label: <Flex gap={10}>
                            <EditOutlined /> <Text>Edit</Text>
                        </Flex>,
                        onClick: () => {
                            setVisible(true);
                            setActionType("edit");
                            setPlaylist(record);
                        }
                    },
                    {
                        key: 'delete',
                        label: <Flex gap={10}>
                            <DeleteOutlined /> <Text>Delete</Text>
                        </Flex>,
                        onClick: () => deletePlaylist(record.id)
                    }
                ]} />
            }
        },
    ]

    const deletePlaylist = (id) => {
        confirm({
            title: TEXTS["Do you want to delete this playlist?"][language],
            icon: <ExclamationCircleFilled />,
            content: null,
            okText: TEXTS["Yes"][language],
            cancelText: TEXTS["No"][language],
            onOk() {
                const { app_id, mac } = current;

                if (!mac || !app_id) return;

                const body = {
                    app_id,
                    mac,
                    playlist_id: id
                };

                REQUESTS.PLAYLIST.DELETE(body, (data) => {
                    getPlaylists();
                })
            },
        });
    }

    const getPlaylists = () => {
        const { app_id, mac } = current;

        setLoading(true)

        const query = {
            app_id,
            mac,
        };

        REQUESTS.PLAYLIST.GET(query, (data) => {
            setPlaylists(data);
            setLoading(false)
        }, (error) => {
            setLoading(false)
        });
    };

    useEffect(() => {
        if (!current) return;
        if (open) {
            getPlaylists();
        }
    }, [open, current]);

    return (
        <Drawer
            title={<h3><Text>Playlists</Text></h3>}
            onClose={onClose}
            open={open}
            width={resize < 900 ? "95%" : 900}
            destroyOnClose={true}
        >
            <Flex
                justify="space-between"
                align="start"
                style={{ marginBottom: 30 }}
            >
                <h2 className="drawer-title"><Text>Playlists</Text></h2>
                <Button onClick={() => {
                    setVisible(true);
                    setActionType("add");
                }}><PlusOutlined /> <Text>Add Playlist</Text></Button>
            </Flex>
            <Table
                columns={columns}
                dataSource={playlists}
                loading={loading}
                // onChange={handleTableChange}
                // pagination={{
                //     current: currentPage,
                //     total: total,
                //     pageSize: limit,
                //     showSizeChanger: true,
                // }}
                bordered
                scroll={{ x: "max-content" }}
                size={"small"}
            />
            <PlaylistActionsDrawer
                open={visible}
                onClose={() => setVisible(false)}
                current={current}
                playlist={playlist}
                getPlaylists={getPlaylists}
                actionType={actionType}
            />
        </Drawer >
    )
}
