import { useEffect } from 'react';

import { Drawer, Form, Input, Button, notification } from 'antd';

import { REQUESTS } from '../../../api/requests';

import Text from '../../../components/Text';

export default function EditRemarkDrawer({ open, onClose, current, getDevices }) {
    const [form] = Form.useForm();

    const onFinish = ({ remark }) => {
        if (!current) return;

        const body = {
            id: current.id,
            remark,
        }

        REQUESTS.EDIT_DEVICE_REMARK(body, () => {
            onClose();
            getDevices();
            notification.success({
                message: "Success",
                description: "Remark updated successfully"
            })
        }, (err) => {
            notification.error({
                message: "Error",
                description: err
            })
        })
    }

    useEffect(() => {
        if (current) {
            form.setFieldsValue({
                remark: current.remark
            })
        }

        if (!open) {
            form.resetFields();
        }
    }, [current, open])

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={<Text>Edit Remark</Text>}
            destroyOnClose={true}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
                <Form.Item
                    name="remark"
                    label={<Text>Remark</Text>}
                    rules={[
                        { required: true, message: 'Please input remark' },
                        { max: 255, message: 'Remark must be less than 255 characters' }
                    ]}
                >
                    <Input.TextArea showCount maxLength={255} />
                </Form.Item>
                <Form.Item className='center-btn'>
                    <Button htmlType='submit' type="primary">Save</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
