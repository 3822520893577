
import { Button, Modal } from 'antd';
import { REQUESTS } from '../api/requests';
import useStore from '../hooks/use-store';
import Text from './Text';
import IMAGES from '../config/images';

import styles from "./styles/notification.module.scss";

export default function NotificationModal({ open, setOpen = () => { }, notification }) {

    const [profile] = useStore('profile');

    const viewNotification = (id) => {
        if (profile && !profile.email) return;

        const body = {
            email: profile.email,
            ids: [id]
        };

        const callback = (data) => {
            setOpen(false);
        }

        REQUESTS.NOTIFICATIONS.VIEW(body, callback);
    }

    return (
        notification && (
            <Modal
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}

            >
                <div
                    className={styles['body']}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {/* <img src={IMAGES.logo} alt="notification" className={styles['logo']} /> */}
                    <h3 className={styles['title']}>{notification.title}</h3>
                    {notification.image && <img src={notification.image} alt="notification" className={styles['image']} />}
                    <div className={styles['desc']} dangerouslySetInnerHTML={{ __html: notification.desc }} />
                    <Button
                        type='primary'
                        onClick={(e) => {
                            viewNotification(notification.id);
                        }}
                    >
                        <Text>Close</Text>
                    </Button>
                </div>
            </Modal>
        )
    );
}
